import { Helmet } from 'react-helmet'
import React from 'react'
import { graphql } from 'gatsby'

import DocumentContainer from '../components/DocumentContainer'
import Layout from '../components/Layout'
import NewsItem from '../components/NewsItem'
import NewsList from '../components/NewsList'
import Section from '../components/Section'

export default ({ data, location }) => (
  <Layout location={location}>
    <Helmet title="News" />
    <DocumentContainer>
      <Section title="News">
        <NewsList>
          {data.allNewsYaml.edges.map(({ node }) => (
            <NewsItem node={node} permalink key={node.fields.slug} />
          ))}
        </NewsList>
      </Section>
    </DocumentContainer>
  </Layout>
)

export const query = graphql`
  query {
    allNewsYaml(sort: { fields: [date], order: DESC }) {
      edges {
        node {
          name
          date
          body
          fields {
            slug
          }
        }
      }
    }
  }
`
