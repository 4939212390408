import React from 'react'

const isLatin = char => /^[\u0020-\u007e\s]+$/.test(char)

export default text => {
  if (!text.length) return []

  const chunks = []
  const chunk = [text.charAt(0)]
  chunk.latin = isLatin(text.charAt(0))
  chunks.push(chunk)

  text = text.slice(1)

  while (text.length) {
    const char = text.charAt(0)
    const latin = isLatin(char)
    const chunk = chunks[chunks.length - 1]
    if (chunk.latin === latin) {
      chunk.push(char)
    } else {
      const newChunk = [char]
      newChunk.latin = latin
      chunks.push(newChunk)
    }
    text = text.slice(1)
  }

  return chunks.map((chunk, i) => (
    <span
      data-latin={chunk.latin ? 'latin' : 'nonlatin'}
      key={`splitChars-${i}`}
    >
      {chunk.join('')}
    </span>
  ))
}
