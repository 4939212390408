import React from 'react'
import dayjs from 'dayjs'

import markdown from '../utils/markdown'
import splitChars from '../utils/splitChars'
import styles from './NewsItem.module.css'
import stylesText from './Text.module.css'

const Cell = ({ link, children }) =>
  link ? <a href={link}>{children}</a> : <>{children}</>

const NewsItem = ({ node, permalink }) => (
  <article className={styles.NewsItem}>
    <header>
      <Cell link={permalink && node.fields.slug}>
        <time>{dayjs(node.date).format('YYYY-MM-DD')}</time>
        <h1>{splitChars(node.name)}</h1>
      </Cell>
    </header>
    <div className={styles.NewsItem_Body}>
      <div
        className={`${stylesText.Text} ${stylesText.isFontSizeBody} ${stylesText.isLineHeightNormal}`}
      >
        {markdown(node.body)}
      </div>
    </div>
  </article>
)

export default NewsItem
