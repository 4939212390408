import React from 'react'

import styles from './Section.module.css'

const Section = ({ title, children, backwardLink }) => (
  <section className={styles.Section}>
    {backwardLink ? (
      <a className={styles.Section_Header} href={backwardLink}>
        <span className={styles.Section_BackwardIcon} />
        <h1>{title}</h1>
      </a>
    ) : (
      <div className={styles.Section_Header}>
        <h1>{title}</h1>
      </div>
    )}
    <div className={styles.Section_Content}>{children}</div>
  </section>
)

export default Section
