import React from 'react'

import styles from './DocumentContainer.module.css'

const DocumentContainer = ({ children }) => (
  <div className={styles.DocumentContainer}>
    {React.Children.map(children, (child, i) => (
      <div key={i}>{child}</div>
    ))}
  </div>
)

export default DocumentContainer
