import React from 'react'

import styles from './NewsList.module.css'

const NewsList = ({ children }) => (
  <div className={styles.NewsList}>
    {React.Children.map(children, (child, i) => (
      <div key={i}>{child}</div>
    ))}
  </div>
)

export default NewsList
